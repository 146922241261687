import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    color :'grey',
    height : '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default function Loading() {
    
    const classes = useStyles();

    return (
      <div className={classes.root}>
        <CircularProgress color="inherit" thickness={2} size={30}/>
      </div>
    );
}
