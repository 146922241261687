import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    
  },
  button: {
    backgroundColor : 'rgba(0, 99, 136)',
    color : 'white',
    "&:hover":{
         color: 'rgba(0, 99, 136)',
         backgroundColor : 'white',
    }
  },
}));

function ScrollTop(props) {
    const { children } = props;
    const classes = useStyles();
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 1000,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector('#root');
        if (anchor) {   
        anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
        
        }
    };

    return (
        <Zoom in={trigger}>
        <div onClick={handleClick} role="presentation" className={classes.root}>
            {children}
        </div>
        </Zoom>
    );
}

export default function BackToTop(props) {
    const classes = useStyles();
    return (
        <ScrollTop {...props}>
            <Fab className={classes.button} size="small" aria-label="scroll back to top">
                <KeyboardArrowUpIcon />
            </Fab>
        </ScrollTop>
    );
}