import React, { Suspense, useEffect } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import './App.css';
import './index.css';
import Analytics from './seo/Analytics';
import ScrollToTop from './main/composants/global/ScrollToTop';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BackToTop from './main/composants/global/backToTop';
import Loading from './main/composants/global/Loading';

//main
const Accueil = React.lazy(() => import('./main/templates/accueil/Accueil'));
const NotFound = React.lazy(() => import('./main/templates/NotFound'));
const Redirection = React.lazy(() => import('./main/composants/global/redirection'));

const Secteur = React.lazy(() => import('./main/templates/secteurs/MegaSecteur'));
const MetaSecteur = React.lazy(() => import('./main/templates/secteurs/MetaSecteurs'))
const Secteurs = React.lazy(() => import('./main/templates/secteurs/Secteurs'));
const SousSecteur = React.lazy(() => import('./main/templates/secteurs/SousSecteur'));

const Service = React.lazy(() => import('./main/templates/services/MegaService'));
const Services = React.lazy(() => import('./main/templates/services/Services'));
const MetaService = React.lazy(() => import('./main/templates/services/MetaService'))
const SousService = React.lazy(() => import('./main/templates/services/SousService'));

const EtudesEtActu = React.lazy(() => import('./main/templates/etudesEtActu/EtudesEtActu'));
const Etudes = React.lazy(() => import('./main/templates/etudesEtActu/Etudes'));
const Actualites = React.lazy(() => import('./main/templates/etudesEtActu/Actualites'));

// const MereMetiers = React.lazy(() => import('./main/templates/metiers/MereMetiers'));
const Metiers = React.lazy(() => import('./main/templates/metiers/Metiers'));

const Presentation = React.lazy(() => import('./main/templates/presentation/quiSommesNous'));
const Carriere = React.lazy(() => import('./main/templates/carriere/Carriere'));
const OffreEmploi = React.lazy(() => import('./main/templates/offreEmploi/OffreEmploi'));
const CasClient = React.lazy(() => import('./main/templates/casClient/CasClient'));
const Contact = React.lazy(() => import('./main/templates/contact/contact'));

const SolutionNumerique = React.lazy(() => import('./main/templates/solutionsNumeriques/SolutionNumerique'));

const PrioriteMoment = React.lazy(() => import('./main/templates/prioriteMoment/prioriteMoment'));
const Rse = React.lazy(() => import('./main/templates/rse/rse'));
const RechercheReference = React.lazy(() => import('./main/templates/rechercheReference/rechercheReference'));
const Recherche = React.lazy(() => import('./main/templates/recherche/recherche'));
const MentionsLegales = React.lazy(() => import('./main/templates/mentions/mentionsLegales'));
const NosFiliales = React.lazy(() => import('./main/templates/nosFiliales/nosFiliales'));
const Trombinoscope = React.lazy(() => import('./main/templates/Trombinoscope'));

//admin 
const MainAdmin = React.lazy(() => import('./MainAdmin'));
//international
const MainInternational = React.lazy(() => import('./international/templates/MainInternational'));

function App() {

	useEffect(() => {

		const handleHash = () => {
			const hash = window.location.hash;
			if (hash.startsWith('#/')) {
				const newPath = hash.slice(2);
				window.location.href = `/${newPath}`;

			}
		};

		handleHash();

		const hashChangeHandler = () => {
			handleHash();
		};

		window.addEventListener('hashchange', hashChangeHandler);

		return () => {
			window.removeEventListener('hashchange', hashChangeHandler);
		};
	}, []);

	return (
		<Suspense fallback={<Loading />}>
			<BrowserRouter >
				<ScrollToTop />
				<Switch>
					{/* main */}
					<Route exact path="/" render={() => <Accueil />} />

					<Route exact path="/secteurs" render={() => <Secteur />} />
					<Route exact path="/secteurs/:paramMetaSecteur/" render={() => <MetaSecteur />} />
					<Route exact path="/secteurs/:paramMetaSecteur/:paramSecteurs" render={() => <Secteurs />} />
					<Route exact path="/secteurs/:paramMetaSecteur/:paramSecteurs/:paramSousSecteur" render={() => <SousSecteur />} />

					<Route exact path="/services" render={() => <Service />} />
					<Route exact path="/services/:paramMetaService/" render={() => <MetaService />} />
					<Route exact path="/services/:paramMetaService/:paramServices" render={() => <Services />} />
					<Route exact path="/services/:paramMetaService/:paramServices/:paramSousService" render={() => <SousService />} />

					{/* <Route exact path="/metiers" render={() => <MereMetiers />} /> */}
					<Route exact path="/metiers/:paramMetier" render={() => <Metiers />} />
					<Route exact path="/Publications-et-Actualites" render={() => <EtudesEtActu />} />
					<Route exact path="/Publications-et-Actualites/publications/:paramEtudes" render={() => <Etudes />} />
					<Route exact path="/Publications-et-Actualites/actualites/:paramActualites" render={() => <Actualites />} />
					<Route exact path="/qui-sommes-nous" render={() => <Presentation />} />
					<Route exact path="/carrieres" render={() => <Carriere />} />
					<Route exact path="/offres-emploi/:paramOffre" render={() => <OffreEmploi />} />
					<Route exact path="/contact" render={() => <Contact />} />
					<Route exact path="/cas-clients/:paramCasClients" render={() => <CasClient />} />

					<Route exact path="/solution-numerique/:paramSolutionNumerique" render={() => <SolutionNumerique />} />

					<Route exact path="/priorite-du-moment/:paramPriorite" render={() => <PrioriteMoment />} />
					<Route exact path="/rse" render={() => <Rse />} />
					<Route exact path="/references" render={() => <RechercheReference />} />
					<Route exact path="/recherche/:paramRecherche" render={() => <Recherche />} />
					<Route exact path="/mentions-legales" render={() => <MentionsLegales />} />
					<Route exact path="/nos-filiales" render={() => <NosFiliales />} />
					<Route exact path="/notre-equipe" render={() => <Trombinoscope />} />
					{/* international */}
					<Route path="/international" render={() => <MainInternational />} />
					{/* admin */}
					<Route path="/admin" render={() => <MainAdmin />} />
					{/* //not NotFound */}
					<Route path="/404" render={() => <NotFound />} />
					<Route path="*" render={() => <Redirection />} />
				</Switch>
				<Analytics />
				<BackToTop />
			</BrowserRouter >
		</Suspense>
	);
}

export default App;