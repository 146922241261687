import React, { useEffect } from 'react';
import { initGA,initGA4} from "../ga-utils";
import CookieConsent, {
    getCookieConsentValue,
  } from "react-cookie-consent";
import Hotjar from '@hotjar/browser';

const Analytics = () => {

    const location = window.location.href;

    useEffect(() => {
        const isConsent = getCookieConsentValue();
        if (isConsent === "true") {
            handleAcceptCookie();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCookieConsentValue()]);

    const handleAcceptCookie = () => {
        if(process.env.REACT_APP_APP_ENV === 'PROD'){

            if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
                initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, location);
            };
            if(process.env.REACT_APP_GOOGLE_ANALYTICS_4_ID){
                initGA4(process.env.REACT_APP_GOOGLE_ANALYTICS_4_ID, location);
            };
            if(process.env.REACT_APP_HJID && process.env.REACT_APP_HJSV){
                Hotjar.init(process.env.REACT_APP_HJID, process.env.REACT_APP_HJSV);
                Hotjar.stateChange(location);
            }
        }
    };
    
    return (
        <CookieConsent
            location="bottom"
            enableDeclineButton
            buttonText="Accepter"
            declineButtonText="Refuser"
            style={{ 
                background: "#2B373B", 
                textAlign :'center',
            }}
            buttonStyle={{
                background: "rgba(0, 99, 136)",
                color: "white",
                fontWeight: "bolder",
            }}
            containerClasses="cookieContainerClasses"
            onAccept={handleAcceptCookie}
        >
            Ce site Web utilise des cookies pour améliorer l'expérience utilisateur.
        </CookieConsent>
    )
};

export default Analytics;