import * as ReactGA3 from "react-ga";
import ReactGA from "react-ga4";

export const initGA = (id, location) => {
  if (process.env.NODE_ENV === "production") {
    ReactGA3.initialize(id);
    ReactGA3.pageview(location);
  }
};

export const initGA4 = (id, location) => {
  if (process.env.NODE_ENV === "production") {
    ReactGA.initialize([
      {
        trackingId: id,
      },
    ]);
    ReactGA.send({ hitType: "pageview", page: location });
  }
}
